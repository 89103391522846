
import './App.css';
import React from 'react';


function App() {
  return (
    <div>
      <h1 className="text-3xl font-bold underline">
        Hey Jungmin!
      </h1>
    </div>
  );
}

export default App;
